module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"/graphql"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Source Code Pro:400,500,600,700,900","Source Sans Pro:400,500,600,900"]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Library of Open Source Hardware","short_name":"LOSH","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon-192x192.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6ce4e93b17e26faf5f239b0f47910589"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
