// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-markdown-page-tsx": () => import("./../../../src/components/markdown-page.tsx" /* webpackChunkName: "component---src-components-markdown-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-detail-[id]-tsx": () => import("./../../../src/pages/detail/[id].tsx" /* webpackChunkName: "component---src-pages-detail-[id]-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

